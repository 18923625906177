




import Vue from 'vue'
import marked from 'marked'

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    parsedValue(): string {
      return marked(this.value)
    }
  }
})
